import React from 'react';
import { VideoFileInfo } from 'types/VideoFileInfo';

interface IVideoFileContext {
    videoFileInfo?: VideoFileInfo;
    setVideoFileInfo: (videoFileInfo: VideoFileInfo) => void;
}

const defaultContext: IVideoFileContext = {
    videoFileInfo: undefined,
    setVideoFileInfo: () => {
        // Initial value. Replaced by context provider.
        return '';
    },
};

const VideoFileContext = React.createContext(defaultContext);

export default VideoFileContext;
