export interface FileDownloadInfo {
    data: string;
    fileName: string;
    fileType: string;
}

export const downloadFile = ({ data, fileName, fileType }: FileDownloadInfo) => {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: fileType });
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
};

export const splitFileName = (fileName: string): [string, string] => {
    // Use a regex to split the file name into two parts, the file name and the file extension.
    const fileNameParts = fileName.match(/^(.+)\.([^.]+$)/);
    if (fileNameParts) {
        return [fileNameParts[1], fileNameParts[2]];
    } else {
        return [fileName, ''];
    }
};
