import React, { useCallback, useContext } from 'react';
import VideoFileContext from 'App/VideoFileContext';
import { Grid, IconButton } from '@material-ui/core';
import { prepareVideoFileInfo } from 'lib/video-analysis';
import { HiddenInput } from 'App/HiddenInput';
import { MdFileUpload } from 'react-icons/md';

interface Props {}

const FileManager: React.FunctionComponent<Props> = ({}: Props) => {
    const { videoFileInfo, setVideoFileInfo } = useContext(VideoFileContext);

    const handleVideoUpload = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files != null && event.target.files.length === 1) {
                const file = event.target.files[0];
                prepareVideoFileInfo(file).then((videoInfo) => {
                    setVideoFileInfo(videoInfo);
                });
            }
        },
        [setVideoFileInfo]
    );

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                Loaded: {videoFileInfo?.name}
            </Grid>
            <Grid item xs={12}>
                Video file:
                <HiddenInput
                    type="file"
                    multiple={false}
                    accept={'video/*'}
                    onChange={handleVideoUpload}
                    id="video-upload"
                />
                <label htmlFor="video-upload">
                    <IconButton component="span">
                        <MdFileUpload />
                    </IconButton>
                </label>
            </Grid>
        </Grid>
    );
};

export default FileManager;
