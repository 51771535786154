import React from 'react';
import styled from 'styled-components';

const ErrorHeader = styled.h1`
    color: #8f0000;
`;

const ErrorText = styled.p`
    color: #1f1f1f;
`;

interface Props {
    videoFileName: string;
}

const PersonVideoMappingError: React.FunctionComponent<Props> = ({ videoFileName }: Props) => {
    return (
        <>
            <ErrorHeader>Person to Video Mapping Error</ErrorHeader>
            <ErrorText>There is no entry mapping the video file &apos;{videoFileName}&apos; to a person ID.</ErrorText>
        </>
    );
};

export default PersonVideoMappingError;
