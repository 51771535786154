import React, { useCallback, useEffect, useState } from 'react';
import { MdPause, MdPlayArrow } from 'react-icons/md';
import { Button, Grid, Slider } from '@material-ui/core';
import { timeFormatter } from 'lib/string-format';
import { useKeyDownCatcher } from 'hooks/useKeyDownCatcher';
import { now } from 'moment';

interface Props {
    onPlayPause?: (isPlaying: boolean) => void;
    onSeek?: (value: number) => void;
    isPlaying: boolean;
    playbackPosition: number;
    minPlaybackPosition: number;
    maxPlaybackPosition: number;
}

const VideoControls: React.FunctionComponent<Props> = ({
    onPlayPause,
    onSeek,
    isPlaying,
    minPlaybackPosition,
    maxPlaybackPosition,
    playbackPosition,
}: Props) => {
    const [shouldSeekTo, setShouldSeekTo] = useState<[number, number] | undefined>(undefined);
    const [shouldPlay, setShouldPlay] = useState<[number, boolean] | undefined>(undefined);

    const keyDownCatcher = useKeyDownCatcher();

    const handlePlayPause = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            setShouldPlay([now(), !isPlaying]);
        },
        [isPlaying]
    );

    const handleSeekTo = useCallback((e: React.ChangeEvent<unknown>, value: number | number[]) => {
        setShouldSeekTo([now(), value as number]);
    }, []);

    useEffect(() => {
        if (shouldPlay && onPlayPause) {
            onPlayPause(shouldPlay[1]);
        }
    }, [onPlayPause, shouldPlay]);

    useEffect(() => {
        if (shouldSeekTo && onSeek) {
            onSeek(shouldSeekTo[1]);
        }
    }, [onSeek, shouldSeekTo]);

    return (
        <Grid container spacing={2}>
            <Grid item>
                <Button onKeyDown={keyDownCatcher} variant="contained" onClick={handlePlayPause}>
                    {isPlaying ? <MdPause /> : <MdPlayArrow />}
                </Button>
            </Grid>
            <Grid item>{timeFormatter(minPlaybackPosition, 2)}&nbsp;s</Grid>
            <Grid item xs>
                <Slider
                    min={minPlaybackPosition}
                    step={0.01}
                    max={maxPlaybackPosition}
                    value={playbackPosition}
                    onChange={handleSeekTo}
                    aria-labelledby="continuous-slider"
                />
            </Grid>
            <Grid item>{timeFormatter(maxPlaybackPosition, 2)}&nbsp;s</Grid>
        </Grid>
    );
};

export default VideoControls;
