import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import EatEventsContext from 'App/EatEventsContext';
import { timeFormatter } from 'lib/string-format';
import { BsAlignEnd, BsAlignStart, BsCheckSquare, BsFillPersonFill } from 'react-icons/bs';
import EditorStateContext from 'App/EditorStateContext';
import { FcCancel, FcCheckmark } from 'react-icons/fc';
import {AiOutlineFieldNumber} from "react-icons/ai";

const useStyles = makeStyles({
    tableContainer: {
        maxHeight: 400,
    },
});

interface Props {}

const EventTable: React.FunctionComponent<Props> = (props: Props) => {
    const [activeRowRef, setActiveRowRef] = React.useState<HTMLTableRowElement | null>(null);
    const { eatEvents } = useContext(EatEventsContext);
    const { currentEventId, currentPersonId, setCurrentEventId } = useContext(EditorStateContext);

    const classes = useStyles();

    useEffect(() => {
        activeRowRef?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }, [activeRowRef]);

    const eatEventsFiltered = eatEvents ? eatEvents.filter((e) => e.person === currentPersonId) : undefined;

    return (
        <>
            {eatEventsFiltered && (
                <TableContainer className={classes.tableContainer}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">
                                    <BsFillPersonFill />
                                </TableCell>
                                <TableCell align="center">
                                    <BsAlignStart />
                                </TableCell>
                                <TableCell align="center">
                                    <BsAlignEnd />
                                </TableCell>
                                <TableCell align="center">
                                    <AiOutlineFieldNumber />
                                </TableCell>
                                <TableCell align="center">
                                    <BsCheckSquare />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {eatEventsFiltered.map((eatEvent) => {
                                let assessmentSymbol;
                                if (eatEvent.assessment === 1) {
                                    assessmentSymbol = <FcCheckmark />;
                                } else if (eatEvent.assessment === 0) {
                                    assessmentSymbol = <FcCancel />;
                                }

                                const onClick = () => {
                                    setCurrentEventId(eatEvent.id);
                                };

                                const selected = eatEvent.id === currentEventId;

                                return (
                                    <TableRow
                                        ref={selected ? (r) => setActiveRowRef(r) : undefined}
                                        key={eatEvent.id}
                                        selected={selected}
                                        onClick={onClick}
                                    >
                                        <TableCell align="center">{eatEvent.person}</TableCell>
                                        <TableCell align="center">{timeFormatter(eatEvent.time_start, 2)}</TableCell>
                                        <TableCell align="center">{timeFormatter(eatEvent.time_end, 2)}</TableCell>
                                        <TableCell align="center">{eatEvent.event_type}</TableCell>
                                        <TableCell align="center">{assessmentSymbol}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
};

export default EventTable;
