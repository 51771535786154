import React, { useCallback, useContext } from 'react';
import EatEventsContext from 'App/EatEventsContext';
import { ButtonProps } from '@material-ui/core/Button/Button';
import { Button } from '@material-ui/core';

interface Props {
    eventId: string;
    assessmentValue: number;
}

const AssessmentButton: React.FunctionComponent<ButtonProps & Props> = ({
    children,
    eventId,
    assessmentValue,
    onClick: nativeOnClick,
    ...props
}: ButtonProps & Props) => {
    const { updateEatEvent } = useContext(EatEventsContext);

    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            updateEatEvent(eventId, { assessment: assessmentValue });

            // Execute the original onClick handler, if present.
            nativeOnClick?.(e);
        },
        [assessmentValue, eventId, nativeOnClick, updateEatEvent]
    );

    return (
        <Button onClick={handleClick} {...props}>
            {children}
        </Button>
    );
};

export default AssessmentButton;
