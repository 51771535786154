import { VideoFileInfo } from 'types/VideoFileInfo';

export function getVideoDuration(file: File): Promise<number> {
    return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.preload = 'metadata';
        video.onloadedmetadata = function () {
            window.URL.revokeObjectURL(video.src);
            resolve(video.duration);
        };
        video.src = URL.createObjectURL(file);
    });
}

export async function prepareVideoFileInfo(file: File): Promise<VideoFileInfo> {
    const duration = await getVideoDuration(file);
    return {
        name: file.name,
        objectURL: URL.createObjectURL(file),
        duration,
    };
}
