import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { ALL_EVENT_TYPES, EventType, getFriendlyName } from 'types/EventType';
import { AiOutlineFieldNumber } from 'react-icons/ai';
import { BsChatSquareText } from 'react-icons/bs';

interface Props {
    activeEventType?: EventType;
}

const EventTypeTable: React.FunctionComponent<Props> = ({ activeEventType }: Props) => {
    return (
        <>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">
                            <AiOutlineFieldNumber />
                        </TableCell>
                        <TableCell align="center">
                            <BsChatSquareText />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ALL_EVENT_TYPES.map((eventType) => {
                        const selected = eventType === activeEventType;

                        return (
                            <TableRow key={eventType} selected={selected}>
                                <TableCell align="center">{eventType}</TableCell>
                                <TableCell align="center">{getFriendlyName(eventType)}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </>
    );
};

export default EventTypeTable;
