import React from 'react';

interface IEatEventEditContext {
    currentEventId: string;
    currentPersonId: string;
    setCurrentEventId: (id: string) => void;
    setCurrentPersonId: (id: string) => void;
}

const defaultContext: IEatEventEditContext = {
    currentEventId: '',
    currentPersonId: '',
    setCurrentEventId: () => {
        // Initial value. Replaced by context provider.
    },
    setCurrentPersonId: () => {
        // Initial value. Replaced by context provider.
    },
};

const EditorStateContext = React.createContext(defaultContext);

export default EditorStateContext;
