import React, { useContext } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { HiddenInput } from 'App/HiddenInput';
import { MdFileDownload, MdFileUpload } from 'react-icons/md';
import { downloadFile, splitFileName } from 'lib/files';
import moment from 'moment';
import { eatEventFileContentDecoder } from 'lib/json-decoders';
import EatEventsContext from 'App/EatEventsContext';
import EventTable from 'App/FileManager/EventTable';

interface Props {}

const EventFileManager: React.FunctionComponent<Props> = ({}: Props) => {
    const { setEventFileContent, getEventFileContent } = useContext(EatEventsContext);
    const [prevEventsFileName, setPrevEventsFileName] = React.useState<string | undefined>(undefined);

    const handleEventJSONDownload = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        const eventFileContent = getEventFileContent();
        if (eventFileContent) {
            const baseName = prevEventsFileName ? splitFileName(prevEventsFileName)[0] : 'events';

            downloadFile({
                data: JSON.stringify(getEventFileContent(), null, 2),
                fileName: baseName + '_' + moment().format('YYYY-MM-DD_HH-m-s') + '.json',
                fileType: 'text/json',
            });
        }
    };

    const handleEventJSONUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files !== null && event.target.files.length === 1) {
            const file = event.target.files[0];
            const objectURL = URL.createObjectURL(file);

            fetch(objectURL)
                .then((response) => {
                    window.URL.revokeObjectURL(objectURL);
                    return response.json();
                })
                .then((jsonData) => {
                    setPrevEventsFileName(file.name);
                    eatEventFileContentDecoder
                        .decodeToPromise(jsonData)
                        .then((eatEventFileContent) => setEventFileContent(eatEventFileContent))
                        .catch((e) => console.error(`Could not decode eat events.\n${e}`));
                });
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                Loaded: {prevEventsFileName}
            </Grid>
            <Grid item xs={12}>
                Event file:
                <HiddenInput
                    type="file"
                    multiple={false}
                    accept={'.json'}
                    onChange={handleEventJSONUpload}
                    id="event-upload"
                />
                <label htmlFor="event-upload">
                    <IconButton component="span">
                        <MdFileUpload />
                    </IconButton>
                </label>
                <IconButton onClick={handleEventJSONDownload}>
                    <MdFileDownload />
                </IconButton>
            </Grid>
            <Grid item xs={12}>
                <EventTable />
            </Grid>
        </Grid>
    );
};

export default EventFileManager;
