import React, { useMemo } from 'react';
import VideoFileContext from 'App/VideoFileContext';
import { VideoFileInfo } from 'types/VideoFileInfo';

interface Props {}

const VideoFileContextProvider = ({ children }: React.PropsWithChildren<Props>) => {
    const [videoFileInfo, setVideoFileInfo] = React.useState<VideoFileInfo | undefined>(undefined);

    // Memoize the value object itself, so it doesn't lead to unnecessary re-renders.
    const providerValueMemo = useMemo(
        () => ({
            videoFileInfo,
            setVideoFileInfo,
        }),
        [videoFileInfo]
    );

    return <VideoFileContext.Provider value={providerValueMemo}>{children}</VideoFileContext.Provider>;
};

export default VideoFileContextProvider;
