import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { VideoFileInfo } from 'types/VideoFileInfo';
import { Button, Grid, makeStyles } from '@material-ui/core';
import EventPlayer from 'App/EventPanel/EventPlayer';
import { FcCancel, FcCheckmark } from 'react-icons/fc';
import AssessmentButton from 'App/EventPanel/AssessmentButton';
import { EatEventWithId } from 'types/EatEvent';
import EditorStateContext from 'App/EditorStateContext';
import { GrLinkNext, GrLinkPrevious } from 'react-icons/gr';
import EventTypeTable from 'App/EventPanel/EventTypeTable';

const useStyles = makeStyles({
    flexGridItem: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    flexAlignStart: {
        alignItems: 'start !important',
    },
    flexAlignEnd: {
        alignItems: 'end !important',
    },
    textEnd: {
        textAlign: 'end',
    },
    textStart: {
        textAlign: 'start',
    },
    button: {
        minWidth: 'unset',
        textTransform: 'unset',
        width: '100%',
        height: '100%',
        padding: 0,
    },
});

interface Props {
    eatEvents: EatEventWithId[];
    personInVideo: string;
    videoFileInfo: VideoFileInfo;
}

const EventPanel: React.FunctionComponent<Props> = ({ videoFileInfo, personInVideo, eatEvents }: Props) => {
    const { setCurrentPersonId } = useContext(EditorStateContext);
    const { currentEventId, setCurrentEventId } = useContext(EditorStateContext);

    useEffect(() => {
        setCurrentPersonId(personInVideo);
    }, [personInVideo, setCurrentPersonId]);

    const filteredEatEvents = useMemo(() => {
        return eatEvents.filter((e) => e.person === personInVideo);
    }, [eatEvents, personInVideo]);

    const maybeCurrentEventIdx = filteredEatEvents.findIndex((e) => e.id === currentEventId);

    // If maybeCurrentEventIdx is -1, then the currentEventId is invalid, e.g., through video file change.
    // In this case, reset the value and wait for re-render.
    useEffect(() => {
        if (maybeCurrentEventIdx < 0) {
            setCurrentEventId(filteredEatEvents[0].id);
        }
    }, [maybeCurrentEventIdx, filteredEatEvents, setCurrentEventId]);

    return (
        <>
            {maybeCurrentEventIdx >= 0 && (
                <EventPanelContent
                    key={personInVideo}
                    currentEventIdx={maybeCurrentEventIdx}
                    videoFileInfo={videoFileInfo}
                    filteredEatEvents={filteredEatEvents}
                />
            )}
        </>
    );
};

interface ContentProps {
    filteredEatEvents: EatEventWithId[];
    videoFileInfo: VideoFileInfo;
    currentEventIdx: number;
}

const EventPanelContent: React.FunctionComponent<ContentProps> = ({
    filteredEatEvents,
    videoFileInfo,
    currentEventIdx,
}: ContentProps) => {
    const { setCurrentEventId } = useContext(EditorStateContext);

    const classes = useStyles();

    const numEvents = filteredEatEvents.length;

    const prevEvent = currentEventIdx > 0 ? filteredEatEvents[currentEventIdx - 1] : undefined;
    const nextEvent = currentEventIdx < numEvents - 1 ? filteredEatEvents[currentEventIdx + 1] : undefined;

    const currentEvent = filteredEatEvents[currentEventIdx];

    const handlePrevEventClick = useCallback(() => {
        prevEvent && setCurrentEventId(prevEvent.id);
    }, [prevEvent, setCurrentEventId]);

    const handleNextEventClick = useCallback(() => {
        nextEvent && setCurrentEventId(nextEvent.id);
    }, [nextEvent, setCurrentEventId]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    Event {currentEventIdx + 1} / {numEvents}
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={6}>
                    <EventPlayer videoFileInfo={videoFileInfo} eatEvent={currentEvent} />
                </Grid>
                <Grid item xs={4} className={`${classes.flexGridItem} ${classes.flexAlignStart}`}>
                    <EventTypeTable activeEventType={currentEvent.event_type} />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={3} />
                <Grid item xs={1}>
                    <Button
                        className={classes.button}
                        onClick={handlePrevEventClick}
                        variant={'contained'}
                        disabled={!prevEvent}
                    >
                        <GrLinkPrevious />
                    </Button>
                </Grid>
                <Grid item xs={2}>
                    <AssessmentButton
                        className={classes.button}
                        onClick={handleNextEventClick}
                        variant={'contained'}
                        eventId={currentEvent.id}
                        assessmentValue={0}
                        style={{ width: '100%' }}
                    >
                        <FcCancel />
                        &nbsp;Reject
                    </AssessmentButton>
                </Grid>
                <Grid item xs={2}>
                    <AssessmentButton
                        className={classes.button}
                        onClick={handleNextEventClick}
                        variant={'contained'}
                        eventId={currentEvent.id}
                        assessmentValue={1}
                        style={{ width: '100%' }}
                    >
                        Accept&nbsp;
                        <FcCheckmark />
                    </AssessmentButton>
                </Grid>
                <Grid item xs={1}>
                    <Button
                        className={classes.button}
                        onClick={handleNextEventClick}
                        variant={'contained'}
                        disabled={!nextEvent}
                    >
                        <GrLinkNext />
                    </Button>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        </>
    );
};

export default EventPanel;
