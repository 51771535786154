import React, { useCallback, useMemo } from 'react';
import { EatEventFileContent, EatEventFileMetadata } from 'types/EatEventFileContent';
import EatEventsContext from 'App/EatEventsContext';
import produce from 'immer';
import { EatEvent, EatEventWithId } from 'types/EatEvent';

interface Props {}

const EatEventsContextProvider = ({ children }: React.PropsWithChildren<Props>) => {
    const [fileMetadata, setFileMetadata] = React.useState<EatEventFileMetadata | undefined>(undefined);
    const [eatEventDict, setEatEventDict] = React.useState<Record<string, EatEvent> | undefined>(undefined);

    const setEatEvents = useCallback((events: EatEventWithId[]) => {
        const newEatEventDict = events.reduce((acc, event) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { id: _, ...remainingEvent } = event;
            acc[event.id] = {
                ...remainingEvent,
            };
            return acc;
        }, {} as Record<string, EatEvent>);
        setEatEventDict(newEatEventDict);
    }, []);

    const setEventFileContent = useCallback(
        (content: EatEventFileContent) => {
            setFileMetadata(content.metadata);
            setEatEvents(content.events);
        },
        [setEatEvents]
    );

    const updateEatEvent = useCallback((eventId: string, eventUpdate: Partial<Omit<EatEvent, 'id'>>) => {
        setEatEventDict((prevState) =>
            produce(prevState, (draftState) => {
                if (draftState) {
                    draftState[eventId] = {
                        ...draftState[eventId],
                        ...eventUpdate,
                    };
                }
            })
        );
    }, []);

    const eatEvents = useMemo(
        () =>
            eatEventDict
                ? Object.entries(eatEventDict)
                      .map(([id, event]) => {
                          return {
                              ...event,
                              id,
                          };
                      })
                      .sort((a, b) => a.time_start - b.time_start)
                : undefined,
        [eatEventDict]
    );

    const getCorrespondingPersonId = useCallback(
        (videoFileName: string) => {
            if (fileMetadata) {
                const mapping = fileMetadata.person_video_mappings.find((pvm) => pvm.video === videoFileName);
                return mapping ? mapping.person : undefined;
            }
        },
        [fileMetadata]
    );

    const getEventFileContent = useCallback((): EatEventFileContent | undefined => {
        if (fileMetadata && eatEvents) {
            return {
                metadata: fileMetadata,
                events: eatEvents,
            };
        }
    }, [eatEvents, fileMetadata]);

    const providerValueMemo = useMemo(
        () => ({
            eatEvents,
            getCorrespondingPersonId,
            setEventFileContent,
            getEventFileContent,
            updateEatEvent,
        }),
        [eatEvents, getCorrespondingPersonId, getEventFileContent, setEventFileContent, updateEatEvent]
    );

    return <EatEventsContext.Provider value={providerValueMemo}>{children}</EatEventsContext.Provider>;
};

export default EatEventsContextProvider;
