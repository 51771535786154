import { NumberValue } from 'd3-scale';
import { VideoFileInfo } from 'types/VideoFileInfo';

const zeroPad = (num: number, places: number) => String(num).padStart(places, '0');

export function timeFormatter(value: NumberValue, fractionDigits = 1): string {
    fractionDigits = Math.floor(fractionDigits);
    let seconds = typeof value === 'number' ? value : value.valueOf();

    // Return a string with seconds divided into hours, minutes and seconds.
    const hh = Math.floor(seconds / 3600);
    seconds = seconds % 3600;
    const mm = Math.floor(seconds / 60);
    seconds = seconds % 60;
    const ss = Math.floor(seconds);

    // Get the fractional part of the seconds.
    const fractionalSeconds = Math.floor((seconds - ss) * Math.pow(10, fractionDigits));

    return (
        (hh > 0 ? `${hh}:` : '') +
        (mm > 0 || hh > 0 ? `${zeroPad(mm, 2)}:` : '') +
        `${zeroPad(ss, 2)}.${zeroPad(fractionalSeconds, fractionDigits)}`
    );
}

export function videoFileFormatter(videoFileInfo: VideoFileInfo) {
    return `${videoFileInfo.name}\u00A0(${timeFormatter(videoFileInfo.duration)})`;
}
