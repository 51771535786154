export enum EventType {
    PERFECT_SEQUENCE = 1,
    PROLONGED_SEQUENCE = 2,
    MULTI_REACH = 3,
    MULTI_EAT = 4,
    MULTI_REACH_MULTI_EAT = 5,
    ABANDONED_REACH = 6,
    ABANDONED_EAT = 7,
    NO_DATA = 8,
}

export const ALL_EVENT_TYPES: EventType[] = [
    EventType.PERFECT_SEQUENCE,
    EventType.PROLONGED_SEQUENCE,
    EventType.MULTI_EAT,
    EventType.MULTI_REACH,
    EventType.MULTI_REACH_MULTI_EAT,
    EventType.ABANDONED_REACH,
    EventType.ABANDONED_EAT,
    EventType.NO_DATA,
];

export const getFriendlyName = (e: EventType): string => {
    switch (e) {
        case EventType.PERFECT_SEQUENCE:
            return 'Perfect Sequence';
        case EventType.PROLONGED_SEQUENCE:
            return 'Prolonged Sequence';
        case EventType.MULTI_EAT:
            return 'Multi-Eat';
        case EventType.MULTI_REACH:
            return 'Multi-Reach';
        case EventType.MULTI_REACH_MULTI_EAT:
            return 'Multi-Reach / Multi-Eat';
        case EventType.ABANDONED_REACH:
            return 'Abandoned Reach';
        case EventType.ABANDONED_EAT:
            return 'Abandoned Eat';
        case EventType.NO_DATA:
            return 'Missing Data';
        default:
            return 'UNKNOWN';
    }
};
