import React from 'react';
import { EatEventFileContent } from 'types/EatEventFileContent';
import { EatEvent, EatEventWithId } from 'types/EatEvent';

interface IEatEventsContext {
    eatEvents?: EatEventWithId[];
    getCorrespondingPersonId: (videoFileName: string) => string | undefined;
    setEventFileContent: (content: EatEventFileContent) => void;
    getEventFileContent: () => EatEventFileContent | undefined;
    updateEatEvent: (eventId: string, eventUpdate: Partial<EatEvent>) => void;
}

const defaultContext: IEatEventsContext = {
    eatEvents: undefined,
    getCorrespondingPersonId: () => {
        // Initial value. Replaced by context provider.
        return undefined;
    },
    setEventFileContent: () => {
        // Initial value. Replaced by context provider.
    },
    getEventFileContent: () => {
        // Initial value. Replaced by context provider.
        return undefined;
    },
    updateEatEvent: () => {
        // Initial value. Replaced by context provider.
    },
};

const EatEventsContext = React.createContext(defaultContext);

export default EatEventsContext;
