import React, { useCallback, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { VideoFileInfo } from 'types/VideoFileInfo';
import { IconButton } from '@material-ui/core';
import { MdVolumeOff, MdVolumeUp } from 'react-icons/md';
import styled from 'styled-components';
import VideoControls from 'App/EventPanel/VideoControls';
import { EatEventWithId } from 'types/EatEvent';

const PlayerWrapper = styled.div`
    position: relative;
`;

const MuteButton = styled(IconButton)`
    position: absolute;
    top: 10px;
    right: 10px;
`;

interface Props {
    videoFileInfo: VideoFileInfo;
    eatEvent: EatEventWithId;
}

const EventPlayer: React.FunctionComponent<Props> = ({ videoFileInfo, eatEvent }: Props) => {
    const [playerRef, setPlayerRef] = useState<ReactPlayer | null>();
    const [muted, setMuted] = useState<boolean>(false);

    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [playbackPosition, setPlaybackPosition] = useState<number>(eatEvent.time_start);

    const onMuteClickHandler = useCallback((e: React.MouseEvent) => {
        setMuted((prevState) => !prevState);
    }, []);

    // When we jump between events, reset the player position.
    useEffect(() => {
        setPlaybackPosition(eatEvent.time_start);
        playerRef?.seekTo(eatEvent.time_start);
    }, [eatEvent, playerRef]);

    // Do things when player progresses. With ReactPlayer.onProgress, there should be a more elegant
    // way to do this than with a interval; unfortunately, this method is never executed by ReactPlayer.
    useEffect(() => {
        if (playerRef) {
            const interval = setInterval(() => {
                const currentPlayerTime = playerRef.getCurrentTime();

                // Jump to beginning of event when the player exceeds the end time of the current event.
                if (currentPlayerTime > eatEvent.time_end) {
                    playerRef?.seekTo(eatEvent.time_start);
                }

                // Update the playback position from the player.
                setPlaybackPosition(playerRef?.getCurrentTime() ?? 0);
            }, 100);

            // Clear the interval on destroy.
            return () => clearInterval(interval);
        }
    }, [eatEvent.time_end, eatEvent.time_start, isPlaying, playerRef, setPlaybackPosition]);

    const handleSeek = useCallback(
        (seekTo: number) => {
            playerRef?.seekTo(seekTo);
        },
        [playerRef]
    );

    const handlePlayPause = useCallback((shouldPlay: boolean) => {
        setIsPlaying(shouldPlay);
    }, []);

    return (
        <PlayerWrapper>
            <ReactPlayer
                ref={(r) => setPlayerRef(r)}
                url={videoFileInfo.objectURL}
                playing={isPlaying}
                pip={false}
                controls={false}
                onDuration={() => {
                    // On first load, as soon as duration is available, seek to the start of the event.
                    playerRef?.seekTo(eatEvent.time_start);
                }}
                muted={muted}
                width="100%"
                height="100%"
            />
            <MuteButton size="small" onClick={onMuteClickHandler}>
                {muted ? <MdVolumeOff /> : <MdVolumeUp />}
            </MuteButton>
            <VideoControls
                isPlaying={isPlaying}
                onSeek={handleSeek}
                onPlayPause={handlePlayPause}
                minPlaybackPosition={eatEvent.time_start}
                maxPlaybackPosition={eatEvent.time_end}
                playbackPosition={playbackPosition}
            />
        </PlayerWrapper>
    );
};

export default EventPlayer;
