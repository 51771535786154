import React, { useMemo } from 'react';
import EditorStateContext from 'App/EditorStateContext';

interface Props {}

const EditorStateContextProvider = ({ children }: React.PropsWithChildren<Props>) => {
    const [currentEventId, setCurrentEventId] = React.useState<string>('');
    const [currentPersonId, setCurrentPersonId] = React.useState<string>('');

    const providerValueMemo = useMemo(
        () => ({
            currentEventId,
            currentPersonId,
            setCurrentEventId,
            setCurrentPersonId,
        }),
        [currentEventId, currentPersonId]
    );

    return <EditorStateContext.Provider value={providerValueMemo}>{children}</EditorStateContext.Provider>;
};

export default EditorStateContextProvider;
