import React, { useContext } from 'react';
import EventFileManager from 'App/FileManager/EventFileManager';
import VideoFileContextProvider from 'App/VideoFileContextProvider';
import EventPanel from 'App/EventPanel/EventPanel';
import { AppBar, Container, Grid, IconButton, makeStyles, Paper, Toolbar, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import VideoFileContext from 'App/VideoFileContext';
import EatEventsContext from 'App/EatEventsContext';
import EatEventsContextProvider from 'App/EatEventsContextProvider';
import PersonVideoMappingError from 'App/PersonVideoMappingError';
import EditorStateContextProvider from 'App/EditorStateContextProvider';
import VideoFileManager from 'App/FileManager/VideoFileManager';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    mainContainer: {
        padding: theme.spacing(2),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

function App() {
    const classes = useStyles();

    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Collective Eating - Minimal Event Editor
                    </Typography>
                </Toolbar>
            </AppBar>
            <VideoFileContextProvider>
                <EatEventsContextProvider>
                    <EditorStateContextProvider>
                        <AppContent />
                    </EditorStateContextProvider>
                </EatEventsContextProvider>
            </VideoFileContextProvider>
        </>
    );
}

const AppContent: React.FunctionComponent = () => {
    const { videoFileInfo } = useContext(VideoFileContext);
    const { eatEvents, getCorrespondingPersonId } = useContext(EatEventsContext);

    const personInVideo = videoFileInfo ? getCorrespondingPersonId(videoFileInfo.name) : undefined;

    const classes = useStyles();

    return (
        <Container className={classes.mainContainer}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <VideoFileManager />
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <EventFileManager />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={8}>
                    <Paper className={classes.paper}>
                        {videoFileInfo &&
                            eatEvents &&
                            eatEvents.length > 0 &&
                            (personInVideo ? (
                                <EventPanel
                                    eatEvents={eatEvents}
                                    personInVideo={personInVideo}
                                    videoFileInfo={videoFileInfo}
                                />
                            ) : (
                                <PersonVideoMappingError videoFileName={videoFileInfo.name} />
                            ))}
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default App;
